import React from 'react'

import Layout from '../components/layout'

const Impress = () => {
    return (
        <Layout>
            <div className="max-w-screen-xl px-4 mx-auto">
                <h1 className="mt-2 text-3xl lg:text-4xl font-bold font-display mb-5">Imprint</h1>

                <p className="text-lg font-bold mb-2">Angaben gemäß § 5 TMG</p>
                <p className="mb-2">
                    Devin Munsch<br />
                    Kösters Kamp 3<br />
                    48317 Drensteinfurt<br />
                </p>

                <p className="text-lg font-bold mt-5 mb-2">Kontakt</p>
                <p>Telefon: +491758670916</p>
                <p>E-Mail: kontakt@devinmunsch.de</p>

                <h2 className="text-lg font-bold mt-5 mb-2">Streitschlichtung</h2>
                <p className="mb-2">
                    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr.<br />
                    Unsere E-Mail-Adresse finden Sie oben im Impressum.<br />

                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br />
                </p>
                <p className="mt-5">Quelle: https://www.e-recht24.de/impressum-generator.html</p>
            </div>
        </Layout>
    )
}

export default Impress